import { Intent } from "@blueprintjs/core";
import {
  AirlockBlockConfig,
  BlockCellId,
  BlockConfig,
  CellContentsPayload,
  CellId,
  CellType,
  ChartCellId,
  CodeCellId,
  CollapsibleCellId,
  ComponentImportCellId,
  DELETE_CELL,
  DbtMetricCellId,
  DisplayTableCellId,
  DisplayTableConfigId,
  Equals,
  ExploreCellId,
  FilterCellId,
  MapCellId,
  MarkdownCellId,
  MetricCellId,
  ParameterId,
  ParameterName,
  PivotCellId,
  SQLCellBlockConfig,
  SqlCellId,
  TextCellId,
  UPDATE_BLOCK_CELL,
  VegaChartCellId,
  WritebackCellId,
  assertNever,
  getChartReferencedDataFrameNames,
  getDateTimeString,
  notEmpty,
  replaceChartDataFrameName,
  uuid,
} from "@hex/common";
import { useCallback } from "react";
import { Literal, Record as RRecord, Static, Unknown } from "runtypes";

import { useToaster } from "../../components/common/Toasts";
import {
  BlockCellMpFragment,
  ChartCellMpFragment,
  CollapsibleCellMpFragment,
  ComponentImportCellMpFragment,
  DisplayTableCellMpFragment,
  ExploreCellMpFragment,
  FilterCellMpFragment,
  InputCellMpFragment,
  MapCellMpFragment,
  PivotCellMpFragment,
  VegaChartCellMpFragment,
} from "../../hex-version-multiplayer/HexVersionMPModel.generated";
import { useCellContentsGetter } from "../../hex-version-multiplayer/state-hooks/cellContentsStateHooks";
import { useCellGetter } from "../../hex-version-multiplayer/state-hooks/cellStateHooks";
import { useImportedComponentIdsGetter } from "../../hex-version-multiplayer/state-hooks/importedComponentHooks";
import { useGetUniqueInputCellName } from "../../hooks/cell/useGetUniqueInputCellName";
import { useStore } from "../../redux/hooks";
import {
  CellContentsMP,
  CellMP,
  SafeCodeCellMpFragment,
  SafeDbtMetricCellMpFragment,
  SafeMarkdownCellMpFragment,
  SafeMetricCellMpFragment,
  SafeSqlCellMpFragment,
  SafeTextCellMpFragment,
  SafeWritebackCellMpFragment,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { useHexVersionAOContext } from "../../util/hexVersionAOContext";
import { useProjectContext } from "../../util/projectContext";

import { useInsertCellV2 } from "./insert-move/index.js";
import { useGetNewSqlCellFields } from "./useGetNewCellFields";
import { useLastSelectedCellIdGetter, useSelectCell } from "./useSelectCell";

type Stub<T> = Omit<
  T,
  "revision" | "updatedDate" | "createdDate" | "deletedDate" | "id"
>;

export type DisplayTableCellStub = Stub<DisplayTableCellMpFragment> & {
  displayTableConfig: Stub<DisplayTableCellMpFragment["displayTableConfig"]> & {
    columnProperties: readonly Stub<
      DisplayTableCellMpFragment["displayTableConfig"]["columnProperties"][number]
    >[];
  };
};
export type InputCellStub = Stub<InputCellMpFragment>;
export type VegaChartCellStub = Stub<VegaChartCellMpFragment>;
export type MapCellStub = Stub<MapCellMpFragment>;
export type MarkdownCellStub = Stub<SafeMarkdownCellMpFragment>;
export type TextCellStub = Stub<SafeTextCellMpFragment>;
export type CodeCellStub = Stub<SafeCodeCellMpFragment>;
export type SQLCellStub = Stub<SafeSqlCellMpFragment>;
export type MetricCellStub = Stub<SafeMetricCellMpFragment>;
export type WritebackCellStub = Stub<SafeWritebackCellMpFragment>;
export type DbtMetricCellStub = Stub<SafeDbtMetricCellMpFragment>;
export type PivotCellStub = Stub<PivotCellMpFragment>;
export type FilterCellStub = Stub<FilterCellMpFragment>;
export type ComponentImportCellStub = Stub<ComponentImportCellMpFragment>;
export type ChartCellStub = Stub<ChartCellMpFragment>;
export type BlockCellStub = Stub<BlockCellMpFragment>;
export type ExploreCellStub = Stub<ExploreCellMpFragment>;
export type CollapsibleCellStub = Stub<CollapsibleCellMpFragment>;

export type CellContentsStub =
  | DisplayTableCellStub
  | InputCellStub
  | MarkdownCellStub
  | TextCellStub
  | VegaChartCellStub
  | SQLCellStub
  | CodeCellStub
  | MetricCellStub
  | MapCellStub
  | WritebackCellStub
  | DbtMetricCellStub
  | PivotCellStub
  | FilterCellStub
  | ComponentImportCellStub
  | ChartCellStub
  | BlockCellStub
  | ExploreCellStub
  | CollapsibleCellStub;

// If this gives a compiler error, it means that
// `CellContentsStub` is missing a possible cell type
const _areAllCellTypesIncluded: Equals<
  CellContentsStub["__typename"],
  CellContentsMP["__typename"]
> = true;

type CellCombinedMetadata = {
  cellContents: CellContentsStub & { cellId: CellId };
  cellMetadata: Pick<CellMP, "parentBlockCellId" | "parentCellId">;
};

// eslint-disable-next-line max-lines-per-function -- it's long
export function duplicateCellContentsPayload(
  cellContents: CellContentsStub,
  overrides?: {
    inputName?: ParameterName;
    resultVariable?: string;
    source?: string;
  },
): CellContentsPayload {
  cellContents = {
    ...cellContents,
    ...(overrides?.resultVariable
      ? { resultVariable: overrides.resultVariable }
      : {}),
  };
  switch (cellContents.__typename) {
    case "CodeCell":
      return {
        type: CellType.CODE,
        id: uuid() as CodeCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        source: overrides?.source ? overrides.source : cellContents.source,
        cellReferencesParseError: cellContents.cellReferencesParseError,
        cellReferencesV2: cellContents.cellReferencesV2,
      };
    case "MarkdownCell":
      return {
        type: CellType.MARKDOWN,
        id: uuid() as MarkdownCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        source: overrides?.source ? overrides.source : cellContents.source,
        cellReferencesParseError: cellContents.cellReferencesParseError,
        cellReferencesV2: cellContents.cellReferencesV2,
      };
    case "TextCell": {
      return {
        type: CellType.TEXT,
        id: uuid() as TextCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        richText: cellContents.richText,
        cellReferencesParseError: cellContents.cellReferencesParseError,
        cellReferencesV2: cellContents.cellReferencesV2,
      };
    }
    case "DisplayTableCell":
      return {
        type: CellType.DISPLAY_TABLE,
        id: uuid() as DisplayTableCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        dataframe: cellContents.dataframe,
        resultVariable: cellContents.resultVariable,
        displayTableConfig: {
          id: uuid() as DisplayTableConfigId,
          revision: cellContents.displayTableConfig.revision,
          pageSize: cellContents.displayTableConfig.pageSize,
          hideIcons: cellContents.displayTableConfig.hideIcons,
          defaultColumnWidth:
            cellContents.displayTableConfig.defaultColumnWidth,
          hideIndex: cellContents.displayTableConfig.hideIndex,
          sortByColumnDefault:
            cellContents.displayTableConfig.sortByColumnDefault ?? undefined,
          sortDirectionDefault:
            cellContents.displayTableConfig.sortDirectionDefault,
          pivotColumnOrdering:
            cellContents.displayTableConfig.pivotColumnOrdering ?? undefined,
          columnProperties:
            cellContents.displayTableConfig.columnProperties.map((p) => ({
              originalName: p.originalName,
              revision: 0,
              displayFormat: p.displayFormat ?? undefined,
              renameTo: p.renameTo ?? undefined,
              size: p.size ?? undefined,
              wrapText: p.wrapText ?? undefined,
            })),
          customColumnOrdering:
            cellContents.displayTableConfig.customColumnOrdering ?? undefined,
          calcs: cellContents.displayTableConfig.calcs ?? undefined,
          conditionalFormatting:
            cellContents.displayTableConfig.conditionalFormatting ?? undefined,
          filters: cellContents.displayTableConfig.filters ?? undefined,
          hiddenColumns:
            cellContents.displayTableConfig.hiddenColumns ?? undefined,
          pinnedColumns:
            cellContents.displayTableConfig.pinnedColumns ?? undefined,
          pinIndexColumns: cellContents.displayTableConfig.pinIndexColumns,
          showAggregations: cellContents.displayTableConfig.showAggregations,
          columnAggregations:
            cellContents.displayTableConfig.columnAggregations ?? undefined,
        },
      };
    case "Parameter":
      return {
        type: CellType.INPUT,
        id: uuid() as ParameterId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        name: overrides?.inputName ?? cellContents.name,
        outputType: cellContents.outputType,
        inputType: cellContents.inputType,
        options: cellContents.options,
        defaultValueString: cellContents.defaultValueString,
        required: cellContents.required,
      };
    case "SqlCell": {
      const displayTableConfig = cellContents.sqlDisplayTableConfig;
      return {
        type: CellType.SQL,
        id: uuid() as SqlCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        source: overrides?.source ? overrides.source : cellContents.source,
        connectionId: cellContents.connectionId ?? null,
        dataFrameCell: cellContents.dataFrameCell,
        cellReferencesParseError: cellContents.cellReferencesParseError,
        cellReferencesV2: cellContents.cellReferencesV2,
        sqlCellReferencesV3: cellContents.sqlCellReferencesV3,
        jinjaCellReferencesV3: cellContents.jinjaCellReferencesV3,
        jinjaSqlReferences: cellContents.jinjaSqlReferences,
        useRichDisplay: cellContents.useRichDisplay ?? false,
        castDecimals: cellContents.castDecimals,
        useNativeDates: cellContents.useNativeDates,
        loadIntoDataFrame: cellContents.loadIntoDataFrame,
        allowDuplicateColumns: false,
        sqlCellOutputType: cellContents.sqlCellOutputType,
        resultVariable: cellContents.resultVariable,
        sqlDisplayTableConfig: displayTableConfig
          ? {
              id: uuid() as DisplayTableConfigId,
              revision: displayTableConfig.revision,
              pageSize: displayTableConfig.pageSize,
              hideIcons: displayTableConfig.hideIcons,
              defaultColumnWidth: displayTableConfig.defaultColumnWidth,
              hideIndex: displayTableConfig.hideIndex,
              sortByColumnDefault:
                displayTableConfig.sortByColumnDefault ?? undefined,
              sortDirectionDefault: displayTableConfig.sortDirectionDefault,
              pivotColumnOrdering:
                displayTableConfig.pivotColumnOrdering ?? undefined,
              columnProperties: displayTableConfig.columnProperties.map(
                (p) => ({
                  originalName: p.originalName,
                  revision: 0,
                  displayFormat: p.displayFormat ?? undefined,
                  renameTo: p.renameTo ?? undefined,
                  size: p.size ?? undefined,
                  wrapText: p.wrapText ?? undefined,
                }),
              ),
              customColumnOrdering:
                displayTableConfig.customColumnOrdering ?? undefined,
              calcs: displayTableConfig.calcs ?? undefined,
              conditionalFormatting:
                displayTableConfig.conditionalFormatting ?? undefined,
              filters: displayTableConfig.filters ?? undefined,
              hiddenColumns: displayTableConfig.hiddenColumns ?? undefined,
              pinnedColumns: displayTableConfig.pinnedColumns ?? undefined,
              pinIndexColumns: displayTableConfig.pinIndexColumns,
              showAggregations: displayTableConfig.showAggregations,
              columnAggregations:
                displayTableConfig.columnAggregations ?? undefined,
            }
          : null,
      };
    }
    case "VegaChartCell":
      return {
        type: CellType.VEGA_CHART,
        id: uuid() as VegaChartCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        metadata: cellContents.metadata,
        vegaSpec: cellContents.vegaSpec,
        height: cellContents.height,
        selectedLayerIndex: cellContents.selectedLayerIndex,
        defaultInputTimezone: cellContents.defaultInputTimezone,
      };
    case "MetricCell":
      return {
        type: CellType.METRIC,
        id: uuid() as MetricCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        title: cellContents.title ?? "",
        valueVariableName: cellContents.valueVariableName ?? "",
        valueColumn: cellContents.valueColumn ?? undefined,
        valueRowIndex: cellContents.valueRowIndex ?? undefined,
        showComparison: cellContents.showComparison,
        comparisonType: cellContents.comparisonType ?? undefined,
        comparisonVariableName: cellContents.comparisonVariableName ?? "",
        comparisonLabel: cellContents.comparisonLabel ?? "",
        comparisonFormat: cellContents.comparisonFormat ?? undefined,
        comparisonColumn: cellContents.comparisonColumn ?? undefined,
        comparisonRowIndex: cellContents.comparisonRowIndex ?? undefined,
        displayFormat: cellContents.displayFormat ?? undefined,
        valueResultVariable: cellContents.valueResultVariable ?? undefined,
        comparisonResultVariable:
          cellContents.comparisonResultVariable ?? undefined,
        valueAggregate: cellContents.valueAggregate ?? undefined,
        comparisonAggregate: cellContents.comparisonAggregate ?? undefined,
        outputResult: cellContents.outputResult,
      };
    case "MapCell": {
      return {
        type: CellType.MAP,
        id: uuid() as MapCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        height: cellContents.height,
        map: cellContents.map,
      };
    }
    case "ChartCell": {
      const displayTableConfig = cellContents.chartDisplayTableConfig;
      return {
        type: CellType.CHART,
        id: uuid() as ChartCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        height: cellContents.height,
        chartSpec: cellContents.chartSpec,
        chartSelection: cellContents.chartSelection,
        colorMappings: cellContents.colorMappings,
        resultVariable: cellContents.resultVariable,
        outputResult: cellContents.outputResult,
        displayType: cellContents.displayType,
        chartDisplayTableConfig: displayTableConfig
          ? {
              id: uuid() as DisplayTableConfigId,
              revision: displayTableConfig.revision,
              pageSize: displayTableConfig.pageSize,
              hideIcons: displayTableConfig.hideIcons,
              defaultColumnWidth: displayTableConfig.defaultColumnWidth,
              hideIndex: displayTableConfig.hideIndex,
              sortByColumnDefault:
                displayTableConfig.sortByColumnDefault ?? undefined,
              sortDirectionDefault: displayTableConfig.sortDirectionDefault,
              pivotColumnOrdering:
                displayTableConfig.pivotColumnOrdering ?? undefined,
              columnProperties: displayTableConfig.columnProperties.map(
                (p) => ({
                  originalName: p.originalName,
                  revision: 0,
                  displayFormat: p.displayFormat ?? undefined,
                  renameTo: p.renameTo ?? undefined,
                  size: p.size ?? undefined,
                  wrapText: p.wrapText ?? undefined,
                }),
              ),
              customColumnOrdering:
                displayTableConfig.customColumnOrdering ?? undefined,
              calcs: displayTableConfig.calcs ?? undefined,
              conditionalFormatting:
                displayTableConfig.conditionalFormatting ?? undefined,
              filters: displayTableConfig.filters ?? undefined,
              hiddenColumns: displayTableConfig.hiddenColumns ?? undefined,
              pinnedColumns: displayTableConfig.pinnedColumns ?? undefined,
              pinIndexColumns: displayTableConfig.pinIndexColumns,
              showAggregations: displayTableConfig.showAggregations,
              columnAggregations:
                displayTableConfig.columnAggregations ?? undefined,
            }
          : null,
      };
    }
    case "ExploreCell": {
      const displayTableConfig = cellContents.displayTableConfig;
      return {
        type: CellType.EXPLORE,
        id: uuid() as ExploreCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        dataframe: cellContents.exploreDataframe,
        colorMappings: cellContents.colorMappings,
        spec: cellContents.spec,
        semanticProjectVersionId: cellContents.semanticProjectVersionId,
        displayTableConfig: {
          id: uuid() as DisplayTableConfigId,
          revision: displayTableConfig.revision,
          pageSize: displayTableConfig.pageSize,
          hideIcons: displayTableConfig.hideIcons,
          defaultColumnWidth: displayTableConfig.defaultColumnWidth,
          hideIndex: displayTableConfig.hideIndex,
          sortByColumnDefault:
            displayTableConfig.sortByColumnDefault ?? undefined,
          sortDirectionDefault: displayTableConfig.sortDirectionDefault,
          pivotColumnOrdering:
            displayTableConfig.pivotColumnOrdering ?? undefined,
          columnProperties: displayTableConfig.columnProperties.map((p) => ({
            originalName: p.originalName,
            revision: 0,
            displayFormat: p.displayFormat ?? undefined,
            renameTo: p.renameTo ?? undefined,
            size: p.size ?? undefined,
            wrapText: p.wrapText ?? undefined,
          })),
          customColumnOrdering:
            displayTableConfig.customColumnOrdering ?? undefined,
          calcs: displayTableConfig.calcs ?? undefined,
          conditionalFormatting:
            displayTableConfig.conditionalFormatting ?? undefined,
          filters: displayTableConfig.filters ?? undefined,
          hiddenColumns: displayTableConfig.hiddenColumns ?? undefined,
          pinnedColumns: displayTableConfig.pinnedColumns ?? undefined,
          pinIndexColumns: displayTableConfig.pinIndexColumns,
          showAggregations: displayTableConfig.showAggregations,
          columnAggregations:
            displayTableConfig.columnAggregations ?? undefined,
        },
      };
    }
    case "WritebackCell":
      return {
        type: CellType.WRITEBACK,
        id: uuid() as WritebackCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        connectionId: cellContents.connectionId ?? null,
        databaseName: cellContents.databaseName,
        schemaName: cellContents.schemaName,
        tableName: cellContents.tableName,
        dataframeName: cellContents.dataframeName,
        runInLogicView: cellContents.runInLogicView,
        runOnScheduledRun: cellContents.runOnScheduledRun,
        overwrite: cellContents.overwrite,
        runInApp: cellContents.runInApp,
        dynamicTableName: cellContents.dynamicTableName,
        connectionName: cellContents.connectionName ?? null,
      };
    case "DbtMetricCell": {
      const displayTableConfig = cellContents.dbtMetricDisplayTableConfig;
      return {
        type: CellType.DBT_METRIC,
        id: uuid() as DbtMetricCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        connectionId: cellContents.connectionId ?? null,
        useRichDisplay: cellContents.useRichDisplay ?? false,
        castDecimals: cellContents.castDecimals,
        useNativeDates: cellContents.useNativeDates,
        metricIds: cellContents.metricIds,
        selectedTimegrain: cellContents.selectedTimegrain,
        startDate: cellContents.startDate,
        endDate: cellContents.endDate,
        selectedDimensions: cellContents.selectedDimensions
          ? (cellContents.selectedDimensions as string[])
          : null,
        secondaryCalculations: [...cellContents.secondaryCalculations],
        resultVariable: cellContents.resultVariable,
        dbtMetricDisplayTableConfig: displayTableConfig
          ? {
              id: uuid() as DisplayTableConfigId,
              revision: displayTableConfig.revision,
              pageSize: displayTableConfig.pageSize,
              hideIcons: displayTableConfig.hideIcons,
              defaultColumnWidth: displayTableConfig.defaultColumnWidth,
              hideIndex: displayTableConfig.hideIndex,
              sortByColumnDefault:
                displayTableConfig.sortByColumnDefault ?? undefined,
              sortDirectionDefault: displayTableConfig.sortDirectionDefault,
              pivotColumnOrdering:
                displayTableConfig.pivotColumnOrdering ?? undefined,
              columnProperties: displayTableConfig.columnProperties.map(
                (p) => ({
                  originalName: p.originalName,
                  revision: 0,
                  displayFormat: p.displayFormat ?? undefined,
                  renameTo: p.renameTo ?? undefined,
                  size: p.size ?? undefined,
                  wrapText: p.wrapText ?? undefined,
                }),
              ),
              customColumnOrdering:
                displayTableConfig.customColumnOrdering ?? undefined,
              calcs: displayTableConfig.calcs ?? undefined,
              conditionalFormatting:
                displayTableConfig.conditionalFormatting ?? undefined,
              filters: displayTableConfig.filters ?? undefined,
              hiddenColumns: displayTableConfig.hiddenColumns ?? undefined,
              pinnedColumns: displayTableConfig.pinnedColumns ?? undefined,
              pinIndexColumns: displayTableConfig.pinIndexColumns,
            }
          : null,
      };
    }
    case "PivotCell": {
      return {
        type: CellType.PIVOT,
        id: uuid() as PivotCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        dataframe: cellContents.dataframe,
        resultVariable: cellContents.resultVariable,
        pivotConfig: cellContents.pivotConfig,
        castDecimals: cellContents.castDecimals,
        displayTableConfig: {
          id: uuid() as DisplayTableConfigId,
          revision: cellContents.displayTableConfig.revision,
          pageSize: cellContents.displayTableConfig.pageSize,
          hideIcons: cellContents.displayTableConfig.hideIcons,
          defaultColumnWidth:
            cellContents.displayTableConfig.defaultColumnWidth,
          hideIndex: cellContents.displayTableConfig.hideIndex,
          sortByColumnDefault:
            cellContents.displayTableConfig.sortByColumnDefault ?? undefined,
          sortDirectionDefault:
            cellContents.displayTableConfig.sortDirectionDefault,
          pivotColumnOrdering:
            cellContents.displayTableConfig.pivotColumnOrdering ?? undefined,
          columnProperties:
            cellContents.displayTableConfig.columnProperties.map((p) => ({
              originalName: p.originalName,
              revision: 0,
              displayFormat: p.displayFormat ?? undefined,
              renameTo: p.renameTo ?? undefined,
              size: p.size ?? undefined,
              wrapText: p.wrapText ?? undefined,
            })),
          customColumnOrdering:
            cellContents.displayTableConfig.customColumnOrdering ?? undefined,
          calcs: cellContents.displayTableConfig.calcs ?? undefined,
          conditionalFormatting:
            cellContents.displayTableConfig.conditionalFormatting ?? undefined,
          filters: cellContents.displayTableConfig.filters ?? undefined,
          hiddenColumns:
            cellContents.displayTableConfig.hiddenColumns ?? undefined,
          pinnedColumns:
            cellContents.displayTableConfig.pinnedColumns ?? undefined,
          pinIndexColumns: cellContents.displayTableConfig.pinIndexColumns,
        },
      };
    }
    case "FilterCell": {
      return {
        type: CellType.FILTER,
        id: uuid() as FilterCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        filters: cellContents.filters,
        resultVariable: cellContents.resultVariable,
        filterType: cellContents.filterType,
        appMode: cellContents.appMode,
        castDecimals: cellContents.castDecimals,
        useQueryMode: cellContents.useQueryMode,
        useNativeDates: cellContents.useNativeDates,
        cellOutputType: cellContents.cellOutputType,
        dataframe: cellContents.dataframe,
      };
    }
    case "ComponentImportCell": {
      return {
        type: CellType.COMPONENT_IMPORT,
        id: uuid() as ComponentImportCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        componentVersionId: cellContents.componentVersionStub?.id ?? null,
      };
    }
    case "CollapsibleCell": {
      return {
        type: CellType.COLLAPSIBLE,
        id: uuid() as CollapsibleCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
      };
    }
    case "BlockCell": {
      return {
        type: CellType.BLOCK,
        id: uuid() as BlockCellId,
        revision: 0,
        createdDate: getDateTimeString(new Date()),
        updatedDate: getDateTimeString(new Date()),
        blockConfig: null, // initialize the blockconfig to be null, we'll update after copying in the child cells
      };
    }
    default:
      return assertNever(
        cellContents,
        (cellContents as { __typename: string }).__typename,
      );
  }
}

interface UseCopyCellResult {
  copyCells: (cellIds: CellId[]) => Promise<void>;
  cutCells: (cellIds: CellId[]) => Promise<void>;
  // returns if the paste event was for cells or not
  onPasteCells: (evt: ClipboardEvent) => Promise<boolean>;
}

const CellCopyPayload = RRecord({
  hexCopyType: Literal("projectCells"),
  payload: Unknown,
});
type CellCopyPayload = Static<typeof CellCopyPayload>;

export function useCopyCell(): UseCopyCellResult {
  const { dispatchAO } = useHexVersionAOContext();
  const { hexVersionId } = useProjectContext();

  const toaster = useToaster();
  const getImportedComponentIds = useImportedComponentIdsGetter();

  const getLastSelectedCellId = useLastSelectedCellIdGetter();

  const getCellContents = useCellContentsGetter({ safe: true });
  const getCell = useCellGetter({ safe: true });

  const { selectCells, selectFollowingCell } = useSelectCell();
  const insertCell = useInsertCellV2();

  const getUniqueInputCellName = useGetUniqueInputCellName();
  const getNewSqlCellFields = useGetNewSqlCellFields();
  const store = useStore();

  const copyCells = useCallback(
    async (cellIds: CellId[]) => {
      const state = store.getState();
      const cellIdsToCopy: CellId[] = [];

      // if any part of a block is in the list of cell ids, we want to copy the whole block
      const blockCellIds = new Set<BlockCellId>();

      for (const cellId of cellIds) {
        const cell = getCell(cellId);
        const cellContents = getCellContents(cellId);
        // if its a block cell id, grab all of its children and mark it as included
        if (
          cellContents?.__typename === "BlockCell" &&
          cellContents.blockConfig
        ) {
          blockCellIds.add(cellContents.blockCellId);
          cellIdsToCopy.push(
            ...[
              cellId,
              ...hexVersionMPSelectors
                .getCellSelectors(hexVersionId)
                .createSelectSortedChildCells(cellId)(state)
                .map((c) => c.id),
            ],
          );
        }
        // this cell is part of a block and we haven't already imported the block
        else if (
          cell?.parentBlockCellId != null &&
          !blockCellIds.has(cell.parentBlockCellId)
        ) {
          const parentBlockCellContents = hexVersionMPSelectors
            .getCellContentSelectors(hexVersionId)
            .selectByCellContentsId(store.getState(), cell.parentBlockCellId);

          if (
            parentBlockCellContents != null &&
            parentBlockCellContents.__typename === "BlockCell" &&
            parentBlockCellContents.blockConfig != null
          ) {
            blockCellIds.add(cell.parentBlockCellId);
            cellIdsToCopy.push(
              ...[
                parentBlockCellContents.cellId,
                ...hexVersionMPSelectors
                  .getCellSelectors(hexVersionId)
                  .createSelectSortedChildCells(parentBlockCellContents.cellId)(
                    state,
                  )
                  .map((c) => c.id),
              ],
            );
          }
        } else {
          cellIdsToCopy.push(cellId);
        }
      }

      const uniqueCellIdsToCopy = Array.from(new Set(cellIdsToCopy));

      const cellContentsToPaste: CellCombinedMetadata[] = uniqueCellIdsToCopy
        .map((cId) => {
          const cellContents = getCellContents(cId);
          const cell = getCell(cId);
          if (cellContents != null && cell != null) {
            return {
              cellContents,
              cellMetadata: {
                parentCellId: cell.parentCellId,
                parentBlockCellId: cell.parentBlockCellId,
              },
            };
          }
        })
        .filter(notEmpty);

      const payload: CellCopyPayload = {
        hexCopyType: "projectCells",
        payload: cellContentsToPaste,
      };

      await navigator.clipboard.writeText(JSON.stringify(payload));
    },
    [getCell, getCellContents, hexVersionId, store],
  );

  const cutCells = useCallback(
    async (cellIds: CellId[]) => {
      const lastCellId = cellIds[cellIds.length - 1];
      await copyCells(cellIds);
      selectFollowingCell(lastCellId);

      const deletions = cellIds.map((cellId) => DELETE_CELL.create({ cellId }));
      dispatchAO(deletions);
    },
    [dispatchAO, selectFollowingCell, copyCells],
  );

  const onPasteCells = useCallback(
    async (evt: ClipboardEvent): Promise<boolean> => {
      const text = evt.clipboardData?.getData("text");
      if (text == null) {
        return false;
      }

      let rawCopyPayload: unknown;
      try {
        rawCopyPayload = JSON.parse(text);
        // eslint-disable-next-line no-restricted-syntax -- we don't care about the error since the user could be pasting anything
      } catch {
        return false;
      }

      if (!CellCopyPayload.guard(rawCopyPayload)) {
        return false;
      }

      // since we know this is supposed to cell contents
      // prevent any other default paste behavior
      evt.preventDefault();
      // monaco editor doesn't obey prevent default for paste events
      // so we must also stop propagation
      // @see https://github.com/microsoft/monaco-editor/issues/2848
      evt.stopPropagation();

      const copiedCellContents =
        rawCopyPayload.payload as CellCombinedMetadata[];

      const lastSelectedCellId = getLastSelectedCellId();
      const lastSelectedCell =
        lastSelectedCellId != null ? getCell(lastSelectedCellId) : undefined;

      let selectedCellId =
        lastSelectedCellId != null &&
        lastSelectedCell != null &&
        lastSelectedCell.deletedDate == null
          ? lastSelectedCellId
          : undefined;

      const newVariables: string[] = [];
      const newCellIds: CellId[] = [];

      const cellIdMapping: Record<CellId, CellId> = {};
      const blockCellIdMapping: Record<BlockCellId, BlockCellId> = {};
      const resultVariableMapping: Record<string, string> = {};

      const blockConfigs: Record<
        CellId,
        BlockConfig & { blockCellId: BlockCellId }
      > = {};

      for (const content of copiedCellContents) {
        const { cellContents: copiedCellContent, cellMetadata } = content;
        let resultVariable: string | undefined = undefined;

        if (copiedCellContent.__typename === "SqlCell") {
          ({ resultVariable } = getNewSqlCellFields({
            originalName: copiedCellContent.resultVariable,
            extraExistingNames: newVariables,
          }));
          newVariables.push(resultVariable);
          resultVariableMapping[copiedCellContent.resultVariable] =
            resultVariable;
        }

        if (copiedCellContent.__typename === "ComponentImportCell") {
          const componentId =
            copiedCellContent?.componentVersionStub?.componentId;
          const importedComponentIds = getImportedComponentIds();
          if (componentId && importedComponentIds.has(componentId)) {
            toaster.show({
              message: "Cannot import component more than once into a project.",
              intent: Intent.WARNING,
            });
            break;
          }
        }

        let payload = duplicateCellContentsPayload(copiedCellContent, {
          inputName: getUniqueInputCellName(
            copiedCellContent.__typename === "Parameter"
              ? copiedCellContent.name
              : undefined,
          ),
          resultVariable,
        });

        // if we're copying a chart cell in a block, update the chart spec to use the new result variable names
        if (
          copiedCellContent.__typename === "ChartCell" &&
          cellMetadata.parentBlockCellId != null &&
          payload.type === "CHART"
        ) {
          const currentDataframeName = getChartReferencedDataFrameNames(
            payload.chartSpec,
          )[0];
          if (
            currentDataframeName != null &&
            resultVariableMapping[currentDataframeName] != null
          ) {
            const newChartSpec = replaceChartDataFrameName(
              payload.chartSpec,
              currentDataframeName,
              resultVariableMapping[currentDataframeName],
            );
            payload = {
              ...payload,
              chartSpec: newChartSpec,
            };
          }
        }

        const { cellId: newCellId, dispatchResult } = insertCell(payload, {
          location:
            cellMetadata.parentCellId != null
              ? {
                  type: "child",
                  position: "last",
                  parentCellId: cellIdMapping[cellMetadata.parentCellId],
                }
              : selectedCellId != null
                ? {
                    type: "relative",
                    targetCellId: selectedCellId,
                    position: "after",
                  }
                : { type: "child", position: "last", parentCellId: null },
        });
        await dispatchResult.promise;
        cellIdMapping[copiedCellContent.cellId] = newCellId;

        if (
          copiedCellContent.__typename === "BlockCell" &&
          payload.type === "BLOCK" &&
          copiedCellContent.blockConfig
        ) {
          blockConfigs[newCellId] = {
            ...copiedCellContent.blockConfig,
            blockCellId: payload.id,
          };
          blockCellIdMapping[copiedCellContent.blockCellId] = payload.id;
        }
        selectedCellId = newCellId;
        newCellIds.push(newCellId);
      }

      // update the block configs of any blocks we copied to point to the new cell ids
      Object.entries(blockConfigs).forEach((entry) => {
        const [cellId, blockConfig] = entry;

        if (SQLCellBlockConfig.guard(blockConfig)) {
          dispatchAO(
            UPDATE_BLOCK_CELL.create({
              cellId: cellId as CellId,
              blockCellId: entry[1].blockCellId,
              key: "blockConfig",
              value: {
                activeTab: blockConfig.activeTab,
                sqlCellId: cellIdMapping[blockConfig.sqlCellId],
                chartCellId: cellIdMapping[blockConfig.chartCellId],
              },
            }),
          );
          return;
        }
        //TODO(HAL-950): Determine if we want to support this
        else if (AirlockBlockConfig.guard(blockConfig)) {
          throw new Error("airlocks not supported");
        } else {
          assertNever(blockConfig, blockConfig);
        }
      });
      selectCells(newCellIds);
      return true;
    },
    [
      getLastSelectedCellId,
      getCell,
      selectCells,
      getUniqueInputCellName,
      insertCell,
      getNewSqlCellFields,
      getImportedComponentIds,
      toaster,
      dispatchAO,
    ],
  );

  return {
    copyCells,
    cutCells,
    onPasteCells,
  };
}
